<template>
  <div class="r-cover overflow-hidden bg-grey-500">
    <div class="r-cover overflow-auto px-5">
      <div class="w-full max-w-5xl xxl:max-w-6xl mx-auto pb-10 pt-28 md:pt-40">
        <div class="md:flex">
          <div class="pb-8 md:pb-0">
            <h1 class="heading athelas-font text-4xl md:text-6xl font-normal mb-4 leading-none">
              {{ section.title.rendered }}
            </h1>
            <p v-if="section.sub_heading" class="sub-heading font-semibold mb-6">
              {{ section.sub_heading }}
            </p>
            <div v-html="section.intro_text" class="intro-text" />
            <div v-if="section.links" class="links flex">
              <a
                :key="index"
                v-for="(link, index) in section.links"
                :href="utmHref(link.url, 'tier-1-cta')"
                class="flex mr-4 underline"
                target="_blank"
              >
                {{ link.text }}123
                <img class="pl-2" src="@/assets/icon-external.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <ul class="flex flex-wrap md:mt-8 md:-mx-2">
          <li
            :key="index"
            v-for="(subject, index) in subjects"
            class="flex w-2/4 md:w-1/4"
            :class="`subject-block-${index}`"
          >
            <router-link
              class="w-full m-2 px-5 py-2 block bg-white focus:bg-secondary lg:hover:bg-secondary transition-colors duration-300"
              :to="`/explore/subject/${subject.slug}`"
              :title="`Visit: ${subject.title.rendered}`"
              v-html="subject.title.rendered"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="coloured-line h-3 w-full fixed bottom-0 left-0 right-0" :style="`background-color:${sectionColour};`" />
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

let tl = null

export default {
  name: 'Subjects',

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline()
      tl.play()
    })
  },

  beforeRouteUpdate(to, from, next) {
    this.animateOut(next)
  },

  beforeRouteLeave(to, from, next) {
    this.animateOut(next)
  },

  computed: {
    ...mapGetters(['subjects', 'getSectionBySlug']),
    section() {
      return this.getSectionBySlug('subjects')
    },
    media() {
      return this.section.supporting_media
    },
    sectionColour() {
      return this.section.colour
    }
  },

  methods: {
    loadTimeline() {
      tl = gsap.timeline({
        paused: true
      })
      tl.fromTo('.heading', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0, duration: 0.6, ease: 'power1.inOut' }, 1)
      tl.fromTo('.campus-text', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' }, 1)
      tl.fromTo('.intro-text', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.6, ease: 'power1.inOut' }, 1)
      tl.fromTo('.links', { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: 0.6, duration: 0.6, ease: 'power1.inOut' }, 1)
      tl.fromTo('.media-block', { opacity: 0, x: 50 }, { opacity: 1, x: 0, delay: 0.8, duration: 1, ease: 'power1.inOut' }, 1)
      this.subjects.forEach((item, index) => {
        const groupDelay = (index * 0.1)
        tl.fromTo(`.subject-block-${index}`, { opacity: 0, y: -20 }, { opacity: 1, y: 0, delay: groupDelay, duration: 0.6, ease: 'power1.inOut' }, 2)
      })
    },
    animateOut(next) {
      this.$store.commit('toggleMenu', false)
      tl.timeScale(2.5).reverse()
      tl.eventCallback('onReverseComplete', () => {
        next()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
