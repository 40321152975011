<template>
  <div class="r-cover overflow-hidden bg-grey-500">
    <div class="r-cover overflow-auto px-5">
      <div class="w-full max-w-5xl xxl:max-w-6xl mx-auto pb-10 pt-28 md:pt-36">
        <div class="md:flex items-start">
          <div class="md:w-5/12 md:pb-0 md:pr-24">
            <h1
              class="heading athelas-font text-4xl md:text-6xl font-normal mb-8 leading-none opacity-0"
            >
              {{ section.title.rendered }}
            </h1>
            <p
              v-if="section.campuses"
              class="campus-text font-semibold mb-6 opacity-0"
            >
              {{ guidedTourPromptText }}
              <template
                v-for="(item, i1) in guidedTourLinks"
                :key="`gt-link-${item.slug}`"
              >
                <router-link
                  :to="`/guided-tours/${item.slug}`"
                  :title="`Visit the ${item.title} guided tour`"
                  class="underline focus:no-underline lg:hover:no-underline"
                  v-html="item.title"
                />{{ getGuidedTourSuffix(i1) }}
              </template>
            </p>
            <div
              v-html="section.intro_text"
              class="intro-text mb-8 opacity-0"
            />
            <div v-if="section.links" class="links flex flex-wrap opacity-0">
              <a
                :key="index"
                v-for="(link, index) in section.links"
                :href="utmHref(link.url, 'tier-2-cta')"
                class="flex mr-4 underline font-semibold"
                alt=""
                target="_blank"
              >
                {{ link.text }}
                <img class="pl-2" src="@/assets/icon-external.svg" />
              </a>
            </div>
            <div class="mb-new" v-if="isTour && tourChildren">
              <div
                class="flex justify-center md:justify-start fixed bottom-5 left-0 w-full md:initial mobile-cta-below-text"
              >
                <Button
                  class="tour-button z-10 opacity-0 fixed md:relative bg-secondary py-2"
                  :button="{
                    button_text: 'Our facilities up close',
                    button_link: `/guided-tours/${section.slug}/${firstTourContent}`,
                    button_label: `Our ${section.title.rendered} facilities up close`,
                  }"
                />
              </div>
            </div>
          </div>
          <div v-if="media" class="media-block relative md:w-7/12 opacity-0">
            <div class="relative w-full" style="padding-bottom: 56.25%" />
            <iframe
              v-if="media.media_type === 'Video'"
              class="absolute left-0 top-0 w-full h-full jacob-test"
              frameborder="0"
              width="100%"
              height="100%"
              v-bind:src="sanitizeVideoUrl(media.video)"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
            <img
              v-else-if="media.media_type === 'Image'"
              v-bind:src="media.image.url"
              class="absolute left-0 top-0 w-full h-full object-cover"
              :alt="media.image.alt"
            />
          </div>
        </div>

        <div
          v-if="groupSections && groupSections.length > 0"
          class="flex flex-wrap mt-10"
        >
          <div
            :key="sectionIndex"
            v-for="(groupSection, sectionIndex) in groupSections"
            class="w-full mb-4"
          >
            <h2
              v-if="groupSection.title"
              class="athelas-font text-xl mb-2"
              :class="`section-title-${sectionIndex}`"
            >
              {{ groupSection.title }}
            </h2>
            <div class="flex flex-wrap md:-mx-2">
              <div
                :key="index"
                v-for="(group, index) in getGroups(sectionIndex)"
                class="flex w-1/2 md:w-1/6 p-2 opacity-0"
                :class="`group-block-${sectionIndex}-${index}`"
              >
                <router-link
                  :to="`/explore/${section.slug}/${
                    group.slug
                  }/${getFirstContent(group)}`"
                  class="group-block w-full mt-4 md:mt-0 cursor-pointer block"
                >
                  <div v-if="group.thumbnail_image" class="w-full h-32 md:h-28">
                    <img
                      class="w-full h-full object-cover"
                      :src="group.thumbnail_image.url"
                      :alt="group.thumbnail_image.alt"
                    />
                  </div>
                  <div
                    class="group-block__text flex items-center justify-center py-2 px-3 bg-white full-width text-center"
                  >
                    <h2 class="font-normal">
                      {{ htmlDecode(group.title.rendered) }}
                    </h2>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="coloured-line fixed left-0 bottom-0 w-full h-3"
      :style="`background-color:${sectionColour};`"
    />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { mapState, mapGetters } from "vuex";
import Button from "@/components/buttons/Button.vue";

let tl = null;

export default {
  name: "Section",

  components: {
    Button,
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline();
      tl.play();
    });
  },

  watch: {
    $route(from, to) {
      this.$nextTick(() => {
        if (from.meta.isSection && to.meta.isSection) {
          this.loadTimeline();
          tl.play();
        }
      });
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.animateOut(next);
  },

  beforeRouteLeave(to, from, next) {
    this.animateOut(next);
  },

  computed: {
    ...mapState(["mobile", "options"]),
    ...mapGetters([
      "getSectionBySlug",
      "getTourSectionBySlug",
      "getGroupsByID",
    ]),
    section() {
      if (this.$route.params.subject) {
        return this.getSectionBySlug(this.$route.params.subject);
      } else if (this.$route.params.tour) {
        return this.getTourSectionBySlug(this.$route.params.tour);
      }
      return this.getSectionBySlug(this.$route.params.section);
    },
    // Guided Tours.
    guidedTourPromptText() {
      return this.options?.guided_tour_prompt_text || "";
    },
    guidedTourLinks() {
      const { section = {} } = this;
      const { campuses = [] } = section;
      return campuses.map((item) => ({
        slug: item.post_name,
        title: item.post_title,
      }));
    },
    //
    groupSections() {
      return this.section.group_sections;
    },
    isTour() {
      return this.$route.params.tour;
    },
    tourChildren() {
      return this.section.children || [];
    },
    firstTourContent() {
      return this.tourChildren[0]?.post_name;
    },
    media() {
      if (this.$route.params.tour) {
        return this.section.supporting_media;
      }
      return this.section.supporting_media;
    },
    sectionColour() {
      if (this.$route.params.subject) {
        const subjectSection = this.getSectionBySlug("subjects");
        return subjectSection.colour;
      }
      return this.section.colour;
    },
  },

  methods: {
    sanitizeVideoUrl(url) {
      if (url.includes("youtube.com")) {
        return url.replace("youtube.com", "youtube-nocookie.com");
      }
      return url;
    },
    loadTimeline() {
      tl = gsap.timeline({
        paused: true,
      });
      tl.fromTo(
        ".coloured-line",
        { opacity: 0, x: -50 },
        { opacity: 1, x: 0, delay: 0.1, duration: 0.6, ease: "power1.inOut" },
        1
      );
      tl.fromTo(
        ".heading",
        { opacity: 0, x: -50 },
        { opacity: 1, x: 0, delay: 0.1, duration: 0.6, ease: "power1.inOut" },
        1
      );
      if (this.section.acf?.campuses) {
        tl.fromTo(
          ".campus-text",
          { opacity: 0, x: -50 },
          { opacity: 1, x: 0, delay: 0.3, duration: 0.6, ease: "power1.inOut" },
          1
        );
      }
      tl.fromTo(
        ".intro-text",
        { opacity: 0, x: -50 },
        { opacity: 1, x: 0, delay: 0.5, duration: 0.6, ease: "power1.inOut" },
        1
      );
      if (this.section.links) {
        tl.fromTo(
          ".links",
          { opacity: 0, x: -50 },
          { opacity: 1, x: 0, delay: 0.7, duration: 0.6, ease: "power1.inOut" },
          1
        );
      }
      tl.fromTo(
        ".media-block",
        { opacity: 0, x: 50 },
        { opacity: 1, x: 0, delay: 1, duration: 0.6, ease: "power1.inOut" },
        1
      );
      if (this.groupSections) {
        this.groupSections.forEach((groupSection, sectionIndex) => {
          const titleDelay = sectionIndex * 0.3 + 1;
          tl.fromTo(
            `.section-title-${sectionIndex}`,
            { opacity: 0, x: -20 },
            {
              opacity: 1,
              x: 0,
              delay: titleDelay,
              duration: 0.6,
              ease: "power1.inOut",
            },
            1
          );
          if (this.groupSections[sectionIndex].groups) {
            this.groupSections[sectionIndex].groups.forEach((item, index) => {
              const groupDelay = index * 0.2 + 1 + sectionIndex;
              tl.fromTo(
                `.group-block-${sectionIndex}-${index}`,
                { opacity: 0, y: -20 },
                {
                  opacity: 1,
                  y: 0,
                  delay: groupDelay,
                  duration: 0.6,
                  ease: "power1.inOut",
                },
                1
              );
            });
          }
        });
      }
      // Tour timeline animations
      if (this.tourChildren) {
        this.tourChildren.forEach((item, index) => {
          const groupDelay = index * 0.16;
          tl.fromTo(
            `.tour-child-${index}`,
            { opacity: 0, x: -20 },
            {
              opacity: 1,
              x: 0,
              delay: groupDelay,
              duration: 0.6,
              ease: "power1.inOut",
            },
            2
          );
        });
      }
      tl.fromTo(
        ".timeline-line-desktop",
        { width: "0px" },
        { width: "100%", delay: 0, duration: 0.6, ease: "power1.inOut" },
        3.6
      );
      tl.fromTo(
        ".timeline-line-mobile",
        { height: "0px" },
        {
          height: "calc(100% - 20px)",
          delay: 0,
          duration: 2,
          ease: "power1.inOut",
        },
        3.6
      );
      tl.fromTo(
        ".tour-button",
        { opacity: 0, x: -50 },
        { opacity: 1, x: 0, delay: 0, duration: 0.6, ease: "power1.inOut" },
        3
      );
    },
    animateOut(next) {
      this.$store.commit("toggleMenu", false);
      tl.timeScale(2.5).reverse();
      tl.eventCallback("onReverseComplete", () => {
        next();
      });
    },
    getGroups(section) {
      const groupIDs = [];
      if (this.section.group_sections[section].groups) {
        this.section.group_sections[section].groups.forEach((group, index) => {
          groupIDs.push({
            id: group.ID,
            order: index,
          });
        });
      }
      return this.getGroupsByID(groupIDs);
    },
    getFirstContent(group) {
      return group.content[0].post_name;
    },
    getGuidedTourSuffix(index) {
      const { guidedTourLinks } = this;
      const linkLength = guidedTourLinks.length;
      const lengthGt1 = linkLength > 1;
      const lengthGt2 = linkLength > 2;
      const isPenultimate = index === linkLength - 2;
      const isLast = index === linkLength - 1;
      const hasComma = lengthGt2 && !isLast && ",";
      const hasAnd = lengthGt1 && isPenultimate && " and";
      return `${hasComma || ""}${hasAnd || ""} `;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-block__text {
  height: calc(100% - 8rem);

  @screen md {
    height: calc(100% - 7rem);
  }
}
.group-block:hover {
  .group-block__text {
    background: var(--color-secondary);

    h2 {
      font-weight: bold;
    }
  }
}

.timeline-line-mobile {
  top: 10px;
}
@media screen and (min-width: 768px) {
  .md\:initial {
    position: initial;
  }
}
@media screen and (max-width: 767px) {
  .mobile-cta-below-text {
    position: initial;
    margin-top: 2rem;
  }
  .mb-new {
    margin-bottom: 32px;
  }
}
</style>
